import 'jquery';
import './style.scss';

import offside from 'offside-js';

import {
  library,
  dom
} from "@fortawesome/fontawesome-svg-core";
import {
  faInstagram, faLinkedin
} from "@fortawesome/free-brands-svg-icons";

library.add(faInstagram, faLinkedin);
dom.watch();

// Pages
// import single from './pages/single';
import category from './pages/category';
import { Callbacks } from 'jquery';

var htmlelement = document.getElementsByTagName("html")[0];

var myOffside = offside('#offcanvas-menu', {
  // slidingElementsSelector: '#slide-body',
  buttonsSelector: '#menu-button, .close-button',
  slidingSide: 'right',
  debug: true,
  disableCss3dTransforms: false,
  afterOpen: function () {
    $('.site-header').addClass("menu-opened");
  },
  afterClose: function () {
    $('.site-header').removeClass("menu-opened");
  }
});



$(document).ready(function () {

  // remove no transform class from menu slider configuration

  htmlelement.classList.remove("no-csstransforms3d");

  // $('.menu-item-402').click(function(event){
  //   event.preventDefault();
  // })

  // lightgallery

  var $lg = $('#gridGallery');

  $lg.lightGallery({
    // subHtmlSelectorRelative: true,
    height: '2000px',
    preload: 1,
    hideBarsDelay: 0,
    zoomFromImage: false
  });

  $lg.on('onBeforeOpen.lg', function (event) {
    $('#gridGallery').addClass('gallery-open');
    $("html, body").animate({ scrollTop: 0 }, "fast");
    $(document).on('click', function (e) {
      // $this.destroy();
     });
  });

  $lg.on('onBeforeClose.lg', function (event) {
     var footer = document.getElementById('site_footer');
    $('#gridGallery').removeClass('gallery-open');
    $('.site-footer').removeClass('masonry-done');
    window.setTimeout( function(){
      footer.classList.add('masonry-done');
    }, 500 )
  });

  // add caption button to lightgallery options

    var addCaptionButton = function( callback ){
      $lg.on('onAfterOpen.lg', function (event) {
        $('.lg-actions').append('<button href="#" class="caption-button"></button>');
        if (callback && typeof callback === 'function') {
          callback();
        }
      });
    }

    addCaptionButton();

  // show caption

  $(document).on('click', '.caption-button', function () {
    $(".lg-sub-html").toggleClass('show-caption');
  });

});